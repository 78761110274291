import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexCard from '../components/flexcard';
import GiftCardBackground from '../components/giftcardbackground';
import settings from '../../settings';

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/bmw-specials-banner.jpg`;
const bmwspecialoffer = `${settings.IMAGES_BASE_URL}/v2/images/continental/continental-offer-1.jpg`;

const Continential = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Gift Cards" description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!" />
      <div className="giftcardpage">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />

          <section className="performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h2>Special Offers</h2>
              </div>
            </div>
            <FlexCard
              rowType="row"
              image={bmwspecialoffer}
              imageAlt="BURN RUBBER THIS SUMMER"
              header="BURN RUBBER THIS SUMMER"
              subheader="GET 25% OFF A CLASS OR EXPERIENCE"
              text="Summer’s always a big deal, but with 25% off a class or Experience at the BMW Performance Center West in Thermal, California, it’s bound to heat up even more. "
              btnText="Call 888-345-4269 to book"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
            >
              <p>
                Offer valid through 9/30/2024 in Thermal location only. Must take the class by 9/30/2024. Excludes the M4 GT4 Experience, Race License School, M Summer Competition, M Mixed Reality and Private Instruction.
              </p>
              <p>
                Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book, and use code <span className="bold">24CONTICA25</span>.
              </p>
            </FlexCard>
          </section>
        </div>
    </Layout>
  );
};

export default Continential;
